<template>
    <div id="app">
        <header class="header">
            <nav class="navbar is-flex-touch is-justify-content-space-between" role="navigation"
                aria-label="main navigation">
                <div class="navbar-brand">
                    <a class="navbar-item" href="/">
                        <img src="./assets/Logo.png" alt="Logo" />
                    </a>
                </div>
                <div v-if="currentFile !== null || fileId !== ''" class="navbar-center">
                    <div class="columns is-centered mt-1 mb-4">
                        <div class="column has-text-centered">
                            <p class="is-size-3 title">
                                <template v-if="uploadedSize !== fileSize">
                                    {{ uploadedSize }}{{ uploadedSizeUnit }} / {{ fileSize }} {{ fileSizeUnit }}
                                    <!-- ({{ uploadProgress }}%) -->
                                </template>
                                <template v-else-if="uploadedSize === fileSize && currentFile !== null">
                                    {{ $t('Header.FileProcessing') }}
                                </template>
                                <template v-else-if="uploadedSize === fileSize && !isAuth && fileId !== ''">
                                    {{ $t('Header.NoCloseLogin') }}
                                </template>
                            </p>
                        </div>
                    </div>
                    <div class="columns is-centered">
                        <div class="column is-two-thirds">
                            <progress v-if="uploadedSize !== fileSize" class="progress is-primary is-small"
                                style="width:100%;margin-bottom:0.5rem;" :value="uploadProgress" max="100">{{
                                    uploadProgress
                                }}%</progress>
                            <progress v-if="uploadedSize === fileSize" class="progress is-primary is-small"
                                style="width:100%;margin-bottom:0.5rem;"></progress>
                            <p v-if="currentFile !== null" class="has-text-centered">
                                {{ $t('Header.NoClose') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="navbar-end">
                    <!-- <div v-if="store.state.auth.isAuthenticated" class="navbar-item">
                        <MenuButton v-model="sidebarActive" />
                    </div> -->
                    <div v-if="!store.state.auth.isAuthenticated" class="navbar-item">
                        <router-link class="is-primary is-size-5" to="/login">{{ $t('Login.Title') }}</router-link>
                    </div>
                    <div v-if="store.state.auth.isAuthenticated" class="navbar-item">
                        <a @click="handleLogout">{{ $t('General.Menu.LogOut') }}</a>
                    </div>
                </div>
            </nav>
        </header>
        <div class="columns is-gapless">
            <div v-if="store.state.auth.isAuthenticated" :class="{ active: sidebarActive }"
                class="column is-narrow sidebar">
                <!-- <transition mode="out-in" :name="'slide-fade'"> -->
                <Sidebar @selected="toggleSidebar" />
                <!-- </transition> -->
            </div>
            <div class="column content-column" :class="{ active: sidebarActive, 'is-flex is-align-items-center is-justify-content-center': layoutClass === 'centered' }">
                <video v-if="currentFile !== null" autoplay muted loop id="backgroundVideo">
                    <source src="@/assets/media/background-video.mp4" type="video/mp4">
                    Your browser does not support HTML5 video.
                </video>
                <router-view v-slot="{ Component }">
                    <transition mode="out-in" :name="'slide-fade'">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </div>
        </div>
        <footer class="footer">
            <div class="content has-text-centered">
                <p>
                    Cloud NYNE GmbH | Schönbrunner Str. 2/68 | 1040 Wien |
                    <a href="mailto:up@nyne.cloud">up@nyne.cloud</a> | <a href="https://nyne.cloud">nyne.cloud</a> |
                    <a href="https://nyne.cloud/terms-and-conditions" target="_blank">{{ $t('Footer.Terms') }}</a> |
                    <a href="https://nyne.cloud/privacy-policy" target="_blank">{{ $t('Footer.PrivacyPolicy') }}</a>
                </p>
            </div>
        </footer>
        <!-- <CookieBanner /> -->
        <Toast />
        <Transition :name="'fade'">
            <LoadingSpinner />
        </Transition>

        <ConfirmDialog />
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import Sidebar from './components/SideBar.vue';
import Toast from './components/Toast.vue';
import LoadingSpinner from './components/LoadingSpinner.vue';
// import MenuButton from './components/MenuButton.vue';
import ConfirmDialog from './components/ConfirmDialog.vue';
// import CookieBanner from './components/CookieBanner.vue';

import { useStore } from './store';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { onUnmounted } from 'vue';
import { debounce } from './directives/validationDirective';

const { t } = useI18n();
const sidebarActive = ref(false);
const store = useStore();
const router = useRouter();

const currentFile = computed(() => store.state.upload.currentFile);
const fileSize = computed(() => store.state.upload.fileSize);
const fileSizeUnit = computed(() => store.state.upload.fileSizeUnit);
const uploadedSize = computed(() => store.state.upload.uploadedSize);
const uploadedSizeUnit = computed(() => store.state.upload.uploadedSizeUnit);
const uploadProgress = computed(() => store.state.upload.uploadProgress);
const fileId = computed(() => store.state.upload.fileId);
const isAuth = computed(() => store.state.auth.isAuthenticated);
const layoutClass = computed(() => store.state.app.layoutClass);

const toggleSidebar = () => {
    sidebarActive.value = !sidebarActive.value;
};

const handleLogout = async () => {
    await store.dispatch('auth/logout');
    router.push({ name: 'Login' });
};
const timer = ref<any>(null);
const body = document.body;

const showScrollbarForOneSecond = () => {
    clearTimeout(timer.value);
    body.classList.add('show-scrollbar'); // Show the scrollbar on mouse move

    timer.value = setTimeout(() => {
        body.classList.remove('show-scrollbar'); // Hide the scrollbar after a period of inactivity
    }, 1000); // Adjust the timeout as needed
}

const debouncedShowScrollbar = debounce(showScrollbarForOneSecond, 10);

window.addEventListener('mousemove', debouncedShowScrollbar);
window.addEventListener('scroll', debouncedShowScrollbar);
window.addEventListener('touchstart', debouncedShowScrollbar);
window.addEventListener('touchmove', debouncedShowScrollbar);
window.addEventListener('touchend', debouncedShowScrollbar);

onUnmounted(() => {
    window.removeEventListener('mousemove', debouncedShowScrollbar);
    window.removeEventListener('scroll', debouncedShowScrollbar);
    window.removeEventListener('touchstart', debouncedShowScrollbar);
    window.removeEventListener('touchmove', debouncedShowScrollbar);
    window.removeEventListener('touchend', debouncedShowScrollbar);
});


watch(
    () => router.currentRoute.value.meta.title,
    newTitle => {
        if (newTitle) {
            (document as any).title = t(newTitle.toString()) + ' | ' + 'Cloud NYNE';
        } else {
            document.title = 'Cloud NYNE'; // Set a default title if no specific title is provided
        }
    },
);

watch(
  () => store.state.upload.isUploading,
  (isUploading) => {
    if (isUploading) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }
);

// Function to handle the beforeunload event
const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  if (store.state.upload.isUploading) {
    event.preventDefault();
    event.returnValue = ''; // Most browsers will show a generic warning dialog
  }
};

</script>



<style scoped lang="scss">
@import '@/assets/css/variables.scss';

.slide-fade-enter-active {
    transition: all 0.35s ease-in-out;
}

.slide-fade-leave-active {
    // transition: all 0.4s ease-in-out;
    transition: all 0.35s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
    transform: translateX(15%);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translateX(-35%);
    opacity: 0;
}

.fade-enter-active {
    animation: toast-fade-in 0.4s ease-in-out;
}

.fade-leave-active {
    animation: toast-fade-out 0.4s ease-in-out;
}

@keyframes toast-fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes toast-fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.header {
    padding: 10px 30px;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .navbar {
        background-color: transparent;
        /* green color */
    }

    .navbar-item a {
        font-weight: 500;
        text-decoration: none;
        margin-right: 10px;

        &:hover {
            text-decoration: underline;
        }
    }

    .navbar-brand .navbar-item img {
        width: 127px;
        max-height: unset;
        margin-top: 8px;
    }

    .navbar-center {
        width: 50%;
        min-width: 600px;

        .column {
            padding: 0;
        }

        padding-bottom: 0.5rem;
    }

    .navbar-end {
        width: 100px;
        margin-left: 0;

        @media screen and (max-width: 1024px) {
            margin-top: 15px;
        }

        button {
            color: #b71c1c;
            /* red color */
            background-color: white;
        }
    }

    .progress.is-small {
        height: 0.4rem;
    }
}

#app>.columns.is-gapless section {
    // margin-top: -130px;
    width: 100%;
}

.columns.is-gapless {
    margin-right: -1px;
    height: calc(100vh - 175px);
    // margin-top: -175px;
    z-index: 1;
    margin-top: 150px;

    &:not(:last-child) {
        margin-bottom: 0;
    }
}
</style>
