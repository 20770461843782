<template>
    <section class="section">
        <div class="container-fluid">
            <div class="columns is-centered">
                <div class="column is-half">
                    <form v-form-validate="{ submitMethod: onSubmit, locale: locale }" class="box" novalidate>
                        <h1 class="title is-2 has-text-centered">{{ $t('Profile.Title') }}</h1>
                        {{ $t('General.Email') }}: <span>{{ formData.email }}</span><br />
                        <template v-if="formData.dateCreated">
                            {{ $t('Profile.RegistrationDate') }}:
                            <span>{{ formatDate(formData.dateCreated, 'dd.MM.yyyy HH:mm') }}</span>
                            <br />
                        </template>
                        <template v-if="formData.dateActivated">
                            {{ $t('Profile.AccountActivated') }}: <span>{{ formData.dateActivated }}</span><br />
                        </template>
                        <template v-else>
                            {{ $t('Profile.AccountNotActivated') }}
                        </template>

                        <div class="field material-input mt-6">
                            <div class="control">
                                <input required class="input" v-model="formData.firstName" type="text" placeholder="">
                                <label class="label">{{ $t('General.FirstName') }}</label>
                            </div>
                        </div>

                        <div class="field material-input">
                            <div class="control">
                                <input class="input" v-model="formData.lastName" type="text" placeholder="">
                                <label class="label">{{ $t('General.LastName') }}</label>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label checkbox-label">
                                <input type="checkbox" v-model="formData.hasNewsletter" />
                                <span>
                                    <b>{{ $t('Profile.SubscribeToNewsletter') }}</b>
                                </span>
                            </label>
                        </div>

                        <div class="field is-flex is-justify-content-space-between">
                            <div class="">
                                <span v-if="formData.dateDeleted">
                                    {{ $t('Profile.AccountDeletionMarked') }} {{
                                        formatDate(formData.dateDeleted, 'dd.MM.yyyy') }}<br />
                                    <a @click="revertDelete">{{ $t('Profile.RevertDeletion') }}</a></span>
                                <button v-if="!formData.dateDeleted" @click="deleteProfile" class="button is-danger">{{
                                    $t('Profile.Delete') }}</button>
                            </div>
                            <button type="submit" class="button is-primary">{{ $t('Profile.Submit') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
import { onMounted, reactive, watch } from 'vue';
import { useStore } from '../store';
import { AccountView } from '@/models/generated/accountView.model';
import { formatDate } from '@/utils/formatBytes';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
// console.log("locale", locale.value);

// watch(locale, (newLocale) => {
//   console.log("Locale changed to:", newLocale);
  // You can also trigger any additional logic here if needed
// });

const store = useStore();
store.commit('app/setLayout', 'centered');

const formData: AccountView = reactive<AccountView>({
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    dateDeleted: undefined,
    dateActivated: undefined,
    dateCreated: undefined,
    dateLastLogin: undefined,
    countProjects: 0,
    hasNewsletter: false,
    active: false,
    roles: [],
    countFinishedProjects: 0,
    projectIds: [],
});
if (store.state.auth.userData) {
    Object.assign(formData, store.state.auth.userData);
}

watch(() => store.state.auth.userData, (newValue) => {
    // todo try to load values from database?
    if (newValue) {
        Object.assign(formData, newValue);
    }
});

const onSubmit = async (event: any) => {
    if (event) {
        event.preventDefault();
    }
    await store.dispatch('auth/updateProfile', formData);
    Object.assign(formData, store.state.auth.userData);
    store.commit('app/showToast', {
        message: t('Profile.Success'),
        type: 'success',
    });
}

const deleteProfile = async (event: any) => {
    if (event) {
        event.preventDefault();
    }
    await store.dispatch('auth/deleteProfile');
    Object.assign(formData, store.state.auth.userData);
    store.commit('app/showToast', {
        message: t('Profile.SuccessAccountDeleted'),
        type: 'success',
    });
    // router.push({ name: 'Home' });
}

const revertDelete = async (event: any) => {
    if (event) {
        event.preventDefault();
    }
    await store.dispatch('auth/revertDeleteProfile');
    // formData = { ...store.state.auth.userData };
    Object.assign(formData, store.state.auth.userData);
    store.commit('app/showToast', {
        message: t('Profile.SuccessRevertDeleted'),
        type: 'success',
    });
}

onMounted(async () => {
    await store.dispatch('auth/getProfile');
    Object.assign(formData, store.state.auth.userData);
});
</script>
