<template>
    <section class="section">
        <div class="box box-compact">
            <h1 class="title is-1 has-text-centered pb-4">{{ $t('ResetPassword.Title') }}</h1>
            
            <div class="tab-content">
                <div class="login-form email">
                    <form v-form-validate="{ submitMethod: resetPassword, locale: locale }" novalidate>
                        <div class="field material-input">
                            <div class="control has-icons-left">
                                <input class="input" placeholder="" type="email" id="email" v-model="email" disabled readonly />

                                <label class="label" for="email">{{ $t('General.Email') }}</label>
                                <!-- <span class="icon is-small is-left">
                                    <font-awesome-icon icon="envelope" />
                                </span> -->
                            </div>
                        </div>
                        <div class="field material-input">
                            <div class="control has-icons-left">
                                <input placeholder="" class="input" type="password" id="newPassword" v-model="newPassword"
                                    required />

                                <label class="label" for="newPassword">{{ $t('General.NewPassword') }}</label>
                                <!-- <span class="icon is-small is-left">
                                    <font-awesome-icon icon="lock" />
                                </span> -->
                            </div>
                        </div>
                        <div class="field has-text-centered">
                            <button type="submit" class="button is-primary mt-2">{{ $t('ResetPassword.Submit') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { isValidPassword } from '@/directives/validationDirective';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const email = ref('');
const token = ref('');
const newPassword = ref('');

const route = useRoute();
const router = useRouter();
const store = useStore();
store.commit('app/setLayout', 'centered');

// Parse query parameters on component mount
onMounted(() => {
    email.value = route.query.email as string;
    token.value = route.query.token as string;
});

const formIsValid = computed(() => {
    return (
        email.value.length > 0 && token.value.length > 0 && isValidPassword(newPassword.value)
    );
});

const resetPassword = async () => {
    if (!formIsValid.value) {
        return;
    }

    try {
        await store.dispatch('auth/resetPassword', { email: email.value, token: token.value, newPassword: newPassword.value });
        store.commit('app/showToast', {
            message: t('ResetPassword.Success'),
            type: 'success',
        });
        router.push({ name: 'Login' });
    } catch (error) {
        console.error('Reset Password failed:', error);
    }
};
</script>

<style scoped lang="scss">
.login-form {
    background-color: #fff;
    padding: 2rem;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form .field:not(:last-child) {
    margin-bottom: 1rem;
}

.login-form .help.is-danger {
    color: red;
}
</style>
